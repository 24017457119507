// 异步获取数据后显示主页面
export function showPage () {
  setTimeout(function () {
    document.getElementById('page_loading').style.display = 'none'
  }, 500)
  document.getElementById('page_loading').style.opacity = '0'
  document.getElementById('app').style.opacity = '1'
}

// 显示页面加载中
export function hidePage () {
  // setTimeout(function () {
  document.getElementById('page_loading').style.display = 'flex'
  // }, 300)
  document.getElementById('page_loading').style.opacity = '1'
  document.getElementById('app').style.opacity = '0'
}

// 让页面不能滚动
export function noScroll () {
  document.getElementsByTagName('body')[0].className += ' no-scroll'
}

// 让页面恢复滚动
export function autoScroll () {
  document.getElementsByTagName('body')[0].className = document.getElementsByTagName('body')[0].className.replace(/ no-scroll/, '')
}

// 获取参数值
export function getQuery (e, t) {
  const n = window.location.search.substr(1)
  const i = n.match(new RegExp('(^|&)' + e + '=([^&]*)(&|$)'))
  if (i && i[2]) {
    if (t) {
      return decodeURI(i[2])
    } else {
      return i[2]
    }
  } else {
    return null
  }
}

export function checkSupportWebp () {
  return document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') === 0
}

export function getCartData () {
  const res = localStorage.getItem('_cart_goods_v1')
  return res ? JSON.parse(res) : []
}

export function addCartData (item) {
  const res = getCartData()
  let index = -1
  res.forEach((item1, index1) => {
    if (item.skuId === item1.skuId && item.scene === item1.scene) {
      index = index1
    }
  })
  if (index > -1) {
    res[index].num++
    res[index].selected = true
  } else {
    item.selected = true
    res.unshift(item)
  }
  localStorage.setItem('_cart_goods_v1', JSON.stringify(res))
  return res
}

export function updateCartData (item) {
  const res = getCartData()
  let index = -1
  res.forEach((item1, index1) => {
    if (item.skuId === item1.skuId && item.scene === item1.scene) {
      index = index1
    }
  })
  if (res[index]) {
    res[index].num = item.num
    localStorage.setItem('_cart_goods_v1', JSON.stringify(res))
    return res
  }
}

// export function deleteData(item) {
//   let res = getCartData()
//   let index
//   res.forEach((item1, index1) => {
//     if (item.skuId === item1.skuId && item.scene === item1.scene) {
//       index = index1
//     }
//   })
//   res.splice(index, 1)
//   localStorage.setItem('_cart_goods_v1', JSON.stringify(res))
//   return res
// }

export function saveCartDate (skus) {
  localStorage.setItem('_cart_goods_v1', JSON.stringify(skus))
}

/**
 * 设置cookie
 * @param key
 * @param val
 * @param time
 */
export function setCookie (key, val, time) {
  const date = new Date()
  date.setTime(date.getTime() + time * 24 * 3600 * 1000)
  document.cookie = key + '=' + val + ';expires=' + date.toGMTString() + ';path=/'
}

/**
 * 获取cookie
 * @param key
 * @returns {*}
 */
export function getCookie (key) {
  const getCookie2 = document.cookie.replace(/[ ]/g, '')
  const arrCookie = getCookie2.split(';')
  let tips
  for (let i = 0; i < arrCookie.length; i++) {
    const arr = arrCookie[i].split('=')
    if (key === arr[0]) {
      tips = arr[1]
      break
    }
  }
  return tips
}

export function setRootFont () {
  let deviceWidth = document.documentElement.clientWidth
  if (deviceWidth > 640) {
    deviceWidth = 640
  }
  document.documentElement.style.fontSize = deviceWidth / 7.5 + 'px'
}

// export function loadJs(url, callback) {
//   const script = document.createElement('script')
//   script.type = 'text/javascript'
//   if (typeof callback != 'undefined') {
//     if (script.readyState) {
//       script.onreadystatechange = function () {
//         if (script.readyState === 'loaded' || script.readyState === 'complete') {
//           script.onreadystatechange = null
//           callback()
//         }
//       }
//     } else {
//       script.onload = function () {
//         callback()
//       }
//     }
//   }
//   script.src = url
//   document.body.appendChild(script)
// }
