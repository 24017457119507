<template>
  <section class="choose-city" :class="{ 'top-in': topIn }" v-show="modelValue">
    <div class="city-title border-bottom">
      选择城市
      <div class="btn-close" @click="handleClickClose">✕</div>
    </div>
    <div class="city-wrap" ref="cityWrap">
      <div class="city-hot" id="hot">
        <div class="city-hot-title">当前城市</div>
        <div class="city-hot-items">
          <div class="city-hot-item">{{ selectedCity.regionName }}</div>
        </div>
        <div class="city-hot-title">热门城市</div>
        <div class="city-hot-items">
          <div class="city-hot-item" v-for="(item, index) in hotCities" :key="index" @click="handleCityClick(item)">{{ item.regionName }}</div>
        </div>
      </div>
      <div class="city-list">
        <div v-for="(cityList, key) in cityData" :key="key" class="city-category" :id="key">
          <div class="city-letter">{{ key }}</div>
          <div v-for="(item, index) in cityList" :key="index" class="city-item-wrap">
            <div class="city-item-txt border-bottom" @click="handleCityClick(item)">{{ item.regionName }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="city-index left-in">
      <div v-for="(item, index) in letters" :key="index" class="city-index-item" @click="handleLetterClick(item)">
        {{ item === 'hot' ? '热门' : item }}
      </div>
    </div>
  </section>
</template>

<script>
import * as pageUtil from '@/utils/pageUtil'
import request from '@/utils/request'

export default {
  name: 'ChooseCity',
  components: {},
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    selectedCity: {
      type: Object
    }
  },
  data() {
    return {
      letters: ['hot', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'W', 'X', 'Y', 'Z'],
      hotCities: [
        {
          cityCode: '310100',
          id: '1',
          pinYin: 'SHANGHAI',
          regionName: '上海'
        },
        {
          cityCode: '110100',
          id: '3',
          pinYin: 'BEIJING',
          regionName: '北京'
        },
        {
          cityCode: '440100',
          id: '8',
          pinYin: 'GUANGZHOU',
          regionName: '广州'
        },
        {
          cityCode: '330100',
          id: '16',
          pinYin: 'HANGZHOU',
          regionName: '杭州'
        },
        {
          cityCode: '440300',
          id: '28',
          pinYin: 'SHENZHEN',
          regionName: '深圳'
        },
        {
          cityCode: '420100',
          id: '10',
          pinYin: 'WUHAN',
          regionName: '武汉'
        },
        {
          cityCode: '510100',
          id: '70',
          pinYin: 'CHENGDU',
          regionName: '成都'
        },
        {
          cityCode: '500100',
          id: '40',
          pinYin: 'CHONGQING',
          regionName: '重庆'
        },
        {
          cityCode: '320100',
          id: '63',
          pinYin: 'NANJING',
          regionName: '南京'
        }
      ],
      cities: {},
      topIn: false,
      cityData: {}
    }
  },
  mounted() {
    // AMapLoader.load({
    //   key: '956a1ae11b00143df18a13af69ea3a68', // 申请好的Web端开发者Key，首次调用 load 时必填
    //   plugins: ['AMap.Geolocation'] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    // })
    //   .then((AMap) => {
    // let mapObj = new AMap.Map('mapContainer')
    // mapObj.plugin('AMap.Geolocation', () => {
    //   const aMapGeolocation = new AMap.Geolocation({
    //     enableHighAccuracy: true, //是否使用高精度定位，默认:true
    //     timeout: 10000, //超过10秒后停止定位，默认：无穷大
    //     maximumAge: 0, //定位结果缓存0毫秒，默认：0
    //     convert: true, //自动偏移坐标，偏移后的坐标为高德坐标，默认：true
    //     showButton: false, //显示定位按钮，默认：true
    //     buttonPosition: 'LB', //定位按钮停靠位置，默认：'LB'，左下角
    //     buttonOffset: new AMap.Pixel(10, 20), //定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
    //     showMarker: false, //定位成功后在定位到的位置显示点标记，默认：true
    //     showCircle: false, //定位成功后用圆圈表示定位精度范围，默认：true
    //     panToLocation: false, //定位成功后将定位到的位置作为地图中心点，默认：true
    //     zoomToAccuracy: false //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
    //   })
    //   mapObj.addControl(aMapGeolocation)
    //   aMapGeolocation.getCurrentPosition((status, result) => {
    //     if (status === 'complete') {
    //       console.log('定位成功')
    //       // this.setState({
    //       //   location: result.formattedAddress
    //       // })
    //       // latitude = result.position.lat
    //       // longitude = result.position.lng
    //       // this.getCityData(
    //       //     result.addressComponent ? (result.addressComponent.city ||
    //       //         result.addressComponent.province) : '上海')
    //     } else {
    //       console.log('定位失败，使用默认定位')
    //       // this.setState({
    //       //   location: '上海'
    //       // })
    //       // latitude = 31.236050
    //       // longitude = 121.392145
    //       // this.getCityData('上海')
    //     }
    //     console.log(result)
    //   })
    // })
    // mapObj.plugin('AMap.CitySearch', function () {
    //   const citySearch = new AMap.CitySearch()
    //   citySearch.getLocalCity(function (status, result) {
    //     if (status === 'complete' && result.info === 'OK') {
    //       // 查询成功，result即为当前所在城市信息
    //       console.log('ip定位成功')
    //       console.log(result)
    //     } else {
    //       console.log('ip定位失败')
    //       console.log(result)
    //     }
    //   })
    // })
    // })
    // .catch((e) => {
    //   console.log(e)
    // })
    // mapUtil.getCurrentPosition()
  },
  methods: {
    handleClickClose() {
      this.topIn = false
      pageUtil.autoScroll()
      setTimeout(() => {
        this.$emit('update:modelValue', false)
      }, 100)
    },
    handleCityClick(item) {
      if (item.cityCode !== this.selectedCity.cityCode) {
        localStorage.setItem(
          'FGJ.DY.Locator.Selected',
          JSON.stringify({
            data: item,
            expireTime: new Date().getTime() + 3600 * 1000 * 24
          })
        )
        this.$emit('cityChange', item)
      }
      this.handleClickClose()
    },
    handleLetterClick(letter) {
      const obj = document.getElementById(letter)
      this.$refs.cityWrap.scrollTop = obj.offsetTop - 45
    }
  },
  watch: {
    modelValue(newValue) {
      if (newValue) {
        pageUtil.noScroll()
        if (!this.cityData.A) {
          request('/movie/getAllRegion').then((res) => {
            this.cityData = res.data
          })
        }
        setTimeout(() => {
          this.topIn = true
        }, 200)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.choose-city {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  overflow: hidden;
  opacity: 0;
  transform: translate3d(0, -50px, 0);
  background: #fcfcfc;
  transition: opacity 0.2s ease-out, transform 0.2s ease-out, -webkit-transform 0.2s ease-out;

  &.top-in {
    opacity: 1;
    transform: translateZ(0);
  }

  .city-title {
    /*border-bottom: 1px solid #e9e9e9;*/
    width: 100%;
    height: 45px;
    background: #fff;
    color: #000;
    font-size: 16px;
    line-height: 45px;
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 300;

    .btn-close {
      position: absolute;
      top: 0;
      right: 0;
      width: 45px;
      height: 45px;
      color: #777;
      text-align: center;
      font-size: 20px;
    }
  }

  .city-wrap {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    padding-top: 45px;
  }

  .city-hot {
    background-color: #f5f5f5;
    padding: 4vw 0 2vw 4vw;
  }

  .city-hot-title {
    color: #9d9d9d;
    font-size: 13px;
    line-height: 24px;
    margin-bottom: 12px;
  }

  .city-hot-items {
    display: flex;
    flex-wrap: wrap;
  }

  .city-hot-item {
    box-sizing: border-box;
    width: 27.6vw;
    height: 8.26vw;
    line-height: 8.26vw;
    color: #9f9f9f;
    /*border: 1px solid #e4e4e4;*/
    text-align: center;
    font-size: 14px;
    margin-bottom: 12px;
    margin-right: 2.93vw;
    background-color: #fff;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      border: 1px solid #dddddd;
      width: 200%;
      height: 200%;
      transform: scale(0.5);
      transform-origin: 0 0;
      -webkit-transform: scale(0.5);
      -webkit-transform-origin: 0 0;
    }
  }

  .city-hot-item.active {
    /*border-color: #ff4d64;*/
    color: #ff4d64;

    &:after {
      border-color: #ff4d64;
    }
  }

  .city-letter {
    height: 27px;
    line-height: 27px;
    background: #f5f5f5;
    color: #777;
    font-size: 12px;
    /* border-top: 1rpx solid #e4e4e4; */
    /*border-bottom: 1px solid #e4e4e4;*/
    padding-left: 4vw;
    position: relative;
    box-sizing: border-box;
  }

  .city-item-wrap {
    background-color: #fefefe;
    height: 48px;
    line-height: 48px;
    font-size: 14px;
    padding-left: 4vw;

    &:last-child {
      .border-bottom {
        border: none;
      }
    }
  }

  .city-item-txt {
    /*border-bottom: 1px solid #e4e4e4;*/
    box-sizing: border-box;
    height: 100%;
  }

  .city-index {
    z-index: 300;
    position: fixed;
    right: 0;
    top: 50%;
    padding: 3px 0;
    overflow: hidden;
    //-webkit-transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
    //transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
    transition: opacity 0.2s ease-out, transform 0.2s ease-out;
    //transition: opacity 0.2s ease-out, transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
    will-change: transform;
    -webkit-transform: translate3d(100%, -50%, 0);
    transform: translate3d(100%, -50%, 0);

    &.left-in {
      opacity: 1;
      -webkit-transform: translate3d(0, -50%, 0);
      transform: translate3d(0, -50%, 0);
    }
  }

  .city-index-item {
    display: block;
    height: 20px;
    text-align: center;
    font-size: 12px;
    line-height: 20px;
    color: #009fe8;
    padding: 0 5px;
  }

  @media screen and (max-height: 600px) {
    .city-index-item {
      height: 18px;
      line-height: 18px;
    }
  }

  @media screen and (max-height: 550px) {
    .city-index-item {
      height: 15px;
      line-height: 15px;
    }
  }
}
</style>
