<template>
  <section>
    <van-form class="form-add">
      <van-field v-model="state.name" label="收货人" placeholder="请填写收货人姓名" />
      <van-field v-model="state.mobile" label="手机号码" placeholder="请填写收货人手机号" />
      <van-field v-model="state.address" is-link readonly label="所在地区" placeholder="请选择所在地区" @click="showChooseCity = true" />
      <van-popup v-model:show="showChooseCity" round position="bottom">
        <van-cascader
          v-model="areaId"
          title="请选择所在地区"
          :options="areaOptions"
          :field-names="fieldNames"
          @close="showChooseCity = false"
          @change="onChange"
          @finish="onFinish"
        />
      </van-popup>
      <van-field v-model="state.area" label="详细地址" placeholder="街道、楼排号等" />
    </van-form>
    <div class="form-add">
      <van-cell center title="设为默认收货地址">
        <template #right-icon>
          <van-switch v-model="state.is_default" size="24" :active-value="1" :inactive-value="0" active-color="#ee0a24" inactive-color="#dcdee0" />
        </template>
      </van-cell>
    </div>
    <van-button
      class="save-btn"
      color="linear-gradient(to right, #ff6034, #ee0a24)"
      block
      round
      @click="handleSave"
      :loading="loading"
      v-if="useAfterSave"
      >保存并使用</van-button
    >
    <van-button class="save-btn" color="linear-gradient(to right, #ff6034, #ee0a24)" block round @click="handleSave" :loading="loading" v-else
      >保存</van-button
    >
  </section>
</template>

<script>
import { Form, Field, Button, Switch, Cell, Cascader, Popup, Toast } from 'vant'
import request from '@/utils/request'
import eventBus from '@/utils/eventBus'

export default {
  name: 'AddressEdit',
  components: {
    VanForm: Form,
    VanField: Field,
    VanButton: Button,
    VanSwitch: Switch,
    VanCell: Cell,
    VanCascader: Cascader,
    VanPopup: Popup
  },
  props: {
    useAfterSave: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      fieldNames: {
        text: 'name',
        value: 'id',
        children: 'children'
      },
      areaId: '',
      showChooseCity: false,
      areaOptions: [],
      loading: false,
      state: {
        name: '',
        mobile: '',
        areaId: '',
        jd_area: '',
        area: '',
        address: '',
        is_default: 0
      }
    }
  },
  async created () {
    this.init()
    const provice = await this.getArea(0)
    this.areaOptions = provice.map((item) => {
      return {
        ...item,
        children: []
      }
    })
  },
  methods: {
    init () {
      const res = sessionStorage.getItem('_to_edit_address')
      if (res) {
        this.state = JSON.parse(res)
      }
    },
    async getArea (pid) {
      const res = await request.get('/mall/getArea', {
        params: {
          pid
        }
      })
      return res.data
    },
    async onChange ({ value, selectedOptions, tabIndex }) {
      const length = selectedOptions.length
      const lastItem = selectedOptions[length - 1]
      if (tabIndex < 2 || (tabIndex === 2 && lastItem.hasChildren === 1)) {
        const res = await this.getArea(value)
        res.forEach((item) => {
          if (item.hasChildren) {
            item.children = []
          }
        })
        if (res.length > 0) {
          lastItem.children = res
        }
      }
    },
    onFinish ({ selectedOptions }) {
      this.state.address = selectedOptions.map((option) => option.name).join('')
      this.state.areaId = selectedOptions.map((option) => option.id).join('_')
      this.state.jd_area = selectedOptions.map((option) => option.jdCode).join('_')
      this.showChooseCity = false
    },
    async handleSave () {
      if (!this.state.name) {
        Toast('请输入收货人姓名')
        return
      }
      if (!this.state.mobile) {
        Toast('请输入手机号')
        return
      }
      if (!/^1[3456789]\d{9}$/.test(this.state.mobile)) {
        Toast('手机号格式不正确，请重新输入')
        return
      }
      if (!this.state.jd_area) {
        Toast('请选择所在区域')
        return
      }
      if (!this.state.address) {
        Toast('请输入详细地址')
        return
      }
      this.loading = true
      const res = await request.post('/mall/saveUserAddress', this.state)
      eventBus.emit('addressChange', res.data)
      if (this.useAfterSave) {
        window.history.go(-2)
      } else {
        window.history.back()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.form-add {
  margin-top: 10px;
  overflow: hidden;
  border-radius: 8px;
}

.save-btn {
  position: fixed;
  bottom: 15px;
  width: 90vw;
  left: 5vw;
}
</style>
