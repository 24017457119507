exports.hotCities = [
  {
    cityId: 1,
    cityName: '上海'
  },
  {
    cityId: 2,
    cityName: '北京'
  },
  {
    cityId: 3,
    cityName: '杭州'
  },
  {
    cityId: 4,
    cityName: '广州'
  },
  {
    cityId: 5,
    cityName: '南京'
  },
  {
    cityId: 6,
    cityName: '苏州'
  },
  {
    cityId: 7,
    cityName: '深圳'
  },
  {
    cityId: 8,
    cityName: '成都'
  },
  {
    cityId: 9,
    cityName: '重庆'
  },
  {
    cityId: 10,
    cityName: '天津'
  },
  {
    cityId: 11,
    cityName: '宁波'
  },
  {
    cityId: 13,
    cityName: '无锡'
  },
  {
    cityId: 15,
    cityName: '厦门'
  },
  {
    cityId: 16,
    cityName: '武汉'
  },
  {
    cityId: 17,
    cityName: '西安'
  },
  {
    cityId: 18,
    cityName: '沈阳'
  },
  {
    cityId: 19,
    cityName: '大连'
  },
  {
    cityId: 21,
    cityName: '青岛'
  },
  {
    cityId: 22,
    cityName: '济南'
  },
  {
    cityId: 24,
    cityName: '石家庄'
  },
  {
    cityId: 35,
    cityName: '太原'
  },
  {
    cityId: 70,
    cityName: '长春'
  },
  {
    cityId: 79,
    cityName: '哈尔滨'
  },
  {
    cityId: 110,
    cityName: '合肥'
  },
  {
    cityId: 160,
    cityName: '郑州'
  },
  {
    cityId: 206,
    cityName: '珠海'
  },
  {
    cityId: 208,
    cityName: '佛山'
  },
  {
    cityId: 224,
    cityName: '南宁'
  },
  {
    cityId: 267,
    cityName: '昆明'
  },
  {
    cityId: 344,
    cityName: '长沙'
  }
]
