<template>
  <section class="selector-mod" v-show="modelValue" @click="handleClick">
    <div class="selector-container" @click.stop="() => {}">
      <div class="supportFilters-container">
        <h3>影院区域</h3>
        <div class="selector-in">
          <div
            class="selecter-item"
            :class="{ active: selectedIndex === index }"
            v-for="(item, index) in areaFilters"
            :key="index"
            @click="handleAreaClick(index)"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ChooseArea',
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    areaFilters: Array
  },
  data() {
    return {
      selectedIndex: 0
    }
  },
  methods: {
    handleClick() {
      this.$emit('update:modelValue', false)
    },
    handleAreaClick(index) {
      if (this.selectedIndex !== index) {
        this.$emit('areaChange', this.areaFilters[index])
        this.selectedIndex = index
      }
      this.$emit('update:modelValue', false)
    }
  }
}
</script>

<style lang="less" scoped>
.selector-mod {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  top: 11vw;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 210;
  display: block;
  overflow: scroll;

  .selector-container {
    background-color: #fff;
    padding: 0 0 4vw 4vw;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    .supportFilters-container {
      border-width: 0 0 1px;
      padding-bottom: 1.1vw;
      padding-top: 2.7vw;
      position: relative;

      h3 {
        font-size: 13px;
        color: #999;
        font-weight: 400;
        margin-bottom: 3.7vw;
      }

      .selector-in {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
      }

      .selecter-item {
        width: 26vw;
        text-align: center;
        padding: 1.3vw 0;
        font-size: 13px;
        position: relative;
        margin-bottom: 2.1vw;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5vw;

        &:after {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          box-sizing: border-box;
          //width: 100%;
          //height: 100%;
          border: 1px solid #d5d5d5;
          border-radius: 4px;
          pointer-events: none;
          -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
          width: 200%;
          height: 200%;
          -webkit-transform: scale(0.5);
          transform: scale(0.5);
        }

        &.active {
          color: #206CFE;

          &:after {
            border: 1px solid #206CFE;
          }
        }
      }
    }
  }
}
</style>
