<template>
  <div class="product-list">
    <div class="product-item" v-for="(item, index) in list" :key="index" @click="handleGoDetail(item)">
      <div class="cover">
        <img :src="item.pic_url" alt=""/>
      </div>
      <div class="info">
        <p class="p-title">{{ item.name }}</p>
        <p class="p-price">
          <price-box :price="item.retail_price"></price-box>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import PriceBox from '@/components/PriceBox'

export default {
  name: 'ProductList',
  props: {
    list: Array
  },
  components: {
    PriceBox: PriceBox
  },
  methods: {
    handleGoDetail (item) {
      window.location.href = `/mall/product?sku=${item.id}`
    }
  }
}
</script>

<style lang="less" scoped>
.product-list {
  position: relative;
  display: block;
}

.product-item {
  padding: 3vw;
  display: flex;
  color: #000000;
  background-color: #ffffff;
  position: relative;

  &:after {
    pointer-events: none;
    position: absolute;
    content: '';
    height: 1px;
    background: #ededed;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: scaleY(0.5);
    -ms-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }

  .cover {
    display: block;
    width: 30vw;
    height: 30vw;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .info {
    margin-left: 4vw;
    flex: 1;

    .p-title {
      font-size: 15px;
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .p-price {
      margin-top: 10px;

      ::v-deep(.fgj-price) {
        font-size: 15px;

        em {
          font-size: 20px;
        }

        .fgj-price-decimals {
          font-size: 16px;
        }
      }

      em {
        font-size: 18px;
      }

      .fgj-price-decimals {
        font-size: 14px;
      }
    }
  }
}
</style>
