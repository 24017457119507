<template>
  <div :class="{ 'nav-bar': true, shadow: shadow }" :style="{ backgroundColor: bgColor }">
    <div class="left-box">
      <!--      <a class="logo-inner" href="/mall/index" v-if="theme === 'home' && showHomeLink">-->
      <!--        <img src="//img-1.yuangonglife.com/images/42179d85f0ec4c63ba61ffeeae0c8d93.png" alt="" />-->
      <!--      </a>-->
      <a class="back-arrow" v-if="theme === 'product'" :class="{ lite: liteArrow }" @click="handleGoBack"></a>
      <a class="back-arrow lite" v-if="theme === 'common'" @click="handleGoBack"></a>
    </div>
    <div class="middle-box">
      <div class="nav-tabs" v-if="showNavTabs && anchors.length > 0">
        <div
          class="nav-tabs-item"
          :class="{ active: navTabIndex === index }"
          v-for="(item, index) in anchors"
          @click="handleNavTabClick(index)"
          :key="index"
        >
          <span>{{ item.name }}</span>
        </div>
      </div>
      <div class="nav-title" v-if="title">{{ title }}</div>
    </div>
    <div class="right-box">
      <a v-if="searchLink" class="search-link"></a>
      <!--      <a v-if="showHeadmenu && showHomeLink" class="header-menu" @click="handleHeadMenuClick">-->
      <!--        <div class="men-wraps" v-if="showMenuWrap">-->
      <!--          <a href="/" v-if="!isZhjq">-->
      <!--            <i class="iconfont fsl-icon-home-2"></i>-->
      <!--            商城首页-->
      <!--          </a>-->
      <!--          <a href="/my" v-if="!isZhjq">-->
      <!--            <i class="iconfont fsl-icon-my-2"></i>-->
      <!--            个人中心-->
      <!--          </a>-->
      <!--          <a href="/my/orders/giftbag" v-if="isZhjq">-->
      <!--            <i class="iconfont fsl-icon-my-2"></i>-->
      <!--            我的订单-->
      <!--          </a>-->
      <!--        </div>-->
      <!--        <div class="men-mask" v-if="showMenuWrap" @click.stop.prevent="handleTouchSatrt" @touchmove.stop.prevent="handleTouchMove"></div>-->
      <!--      </a>-->
      <a v-if="showMylink" class="user-link" :href="myLink"></a>
      <!--      <a v-if="showOrderlink" class="user-link" :href="myLink"></a>-->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    searchLink: {
      type: String,
      default: ''
    },
    showMylink: {
      type: Boolean,
      default: false
    },
    showHeadmenu: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'common'
    },
    title: {
      type: String,
      default: ''
    },
    anchors: {
      type: Array,
      default: () => {
        return []
      }
    },
    shadow: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      showMenuWrap: false,
      bgColor: 'rgba(255,255,255,1)',
      liteArrow: false,
      navTabIndex: 0,
      showNavTabs: false,
      anchorDomTop: [],
      isZhjq: false,
      showHomeLink: true,
      myLink: '/my/index',
      showOrderlink: false
    }
  },
  created () {
    if (this.theme === 'product') {
      this.bgColor = 'rgba(255,255,255,0)'
      this.initProductScroll()
    }
  },
  mounted () {
    let a = []
    setTimeout(() => {
      this.anchors.forEach((item) => {
        a.push(document.getElementById(item.id).offsetTop)
      })
      this.anchorDomTop = a
    }, 500)
  },
  methods: {
    handleHeadMenuClick () {
      this.showMenuWrap = !this.showMenuWrap
    },
    handleTouchSatrt () {
      this.showMenuWrap = !this.showMenuWrap
    },
    handleTouchMove () {},
    initProductScroll () {
      let winWidth = window.document.documentElement.clientWidth
      window.addEventListener('scroll', (env) => {
        let scrollTop = env.currentTarget.scrollY
        let opacity
        if (scrollTop <= winWidth) {
          opacity = scrollTop / winWidth
          this.liteArrow = false
        } else {
          opacity = 1
          this.liteArrow = true
        }
        this.showNavTabs = scrollTop > winWidth - 200
        this.bgColor = `rgba(255,255,255,${opacity})`

        let indexa = -1
        this.anchorDomTop.forEach((item, index) => {
          if (item <= scrollTop + 45) {
            indexa = index
          }
        })
        this.navTabIndex = indexa
      })
    },
    handleNavTabClick (index) {
      let top = this.anchorDomTop[index] - 43
      document.body.scrollTop = top
      document.documentElement.scrollTop = top
    },
    handleGoBack () {
      if (window.history.length > 0) {
        window.history.back()
      } else {
        window.location.replace('/mall/index')
      }
      // document.referrer === '' ? window.location.replace('/') : window.history.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.nav-bar {
  height: 44px;
  width: 100%;
  box-sizing: border-box;
  font-size: 17px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 300;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: #ffffff;

  padding: 0 12px;
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: space-between;

  &.shadow {
    box-shadow: 0 2px 4px 0 rgba(233, 233, 233, 1);
  }

  &.product {
    background-color: transparent;
  }
}

.left-box {
  .logo-inner {
    display: block;
    //width: 85px;
    height: 30px;

    img {
      display: block;
      //width: 100%;
      height: 100%;
    }
  }

  .back-arrow {
    background: url('//img-1.yuangonglife.com/images/e4624d149cbc4d3184c0c8a47f8dc700.png');
    width: 30px;
    height: 30px;
    background-size: 100%;
    display: block;

    &.lite {
      background: url('//img-1.yuangonglife.com/images/c527727edd30422383ffc76e732b81ca.png');
      background-size: 100%;
      width: 16px;
      height: 16px;
      margin: 2px;
    }
  }
}

.middle-box {
  flex: 1;
  padding: 0 10px;
}

.nav-title {
  position: absolute;
  width: 80vw;
  height: 100%;
  left: 10vw;
  top: 0;
  text-align: center;
  line-height: 44px;
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.nav-tabs {
  position: relative;
  display: flex;
  user-select: none;
  justify-content: center;

  .nav-tabs-item {
    position: relative;
    box-sizing: border-box;
    padding: 0 20px;
    color: #000000;
    font-size: 14px;
    line-height: 44px;
    text-align: center;
    cursor: pointer;

    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
    }

    &.active {
      color: #ee0a24;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 20%;
        z-index: 1;
        height: 3px;
        background-color: #ee0a24;
        border-radius: 3px;
        width: 60%;
      }
    }
  }
}

.right-box {
  display: flex;
  align-items: flex-end;

  .user-link {
    display: block;
    background: url('//img-1.yuangonglife.com/images/419614ddfdc74fecabbae10b1baed490.png') no-repeat center;
    width: 20px;
    height: 20px;
    background-size: 100%;
    margin-left: 8px;
  }

  .header-menu {
    display: block;
    width: 20px;
    height: 20px;
    background-size: 100%;
    margin-left: 8px;
    position: relative;
  }

  .search-link {
    display: block;
    background: url('//img-1.yuangonglife.com/images/5bcea4c4037242b89059969a95980f25.png') no-repeat center;
    width: 20px;
    height: 20px;
    background-size: 100%;
    margin-left: 8px;
  }
}

.men-wraps {
  position: absolute;
  border-radius: 10px;
  background-color: #e53244;
  width: 95px;
  font-size: 11px;
  color: #ffffff;
  left: -70px;
  top: 36px;
  padding: 12px;

  &:before {
    content: '';
    width: 0;
    height: 0;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 10px solid #e53244;
    position: absolute;
    top: -10px;
    right: 10px;
  }

  a {
    height: 18px;
    display: flex;
    margin-top: 10px;
    color: #ffffff;

    &:first-child {
      margin-top: 0;
    }

    i {
      margin-right: 5px;
    }
  }

  z-index: 201;
}

.men-mask {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  touch-action: none;
  z-index: 200;
}
</style>
