<template>
  <div class="back-top" @click="backToTop" v-show="backTopShow"></div>
</template>

<script>
export default {
  name: 'BackTop',
  data() {
    return {
      //是否显示回到顶部
      backTopShow: false,
      // 是否允许操作返回顶部
      backTopAllow: true,
      // 返回顶部所需时间
      backSeconds: 100,
      // 往下滑动多少显示返回顶部（单位：px）
      showPx: 100
    }
  },
  mounted: function () {
    window.addEventListener('scroll', this.backTopShowOperate, true)
  },
  methods: {
    backTopShowOperate: function () {
      if (!this.backTopAllow) return
      let scrollTop = this.getScroll().top
      this.backTopShow = scrollTop > this.showPx
    },
    backToTop: function () {
      // if (!this.backTopAllow) return
      // this.backToTopShow = false
      // this.backTopAllow = false
      document.body.scrollTop = document.documentElement.scrollTop = 0
      // let scrollTop = this.getScroll().top
      // const step = scrollTop / this.backSeconds
      // const backTopInterval = setInterval(() => {
      //   if (scrollTop > 0) {
      //     document.body.scrollTop=document.documentElement.scrollTop -= step
      //   } else {
      //     this.backTopAllow = true
      //     clearInterval(backTopInterval)
      //   }
      // }, 10)
    },
    getScroll: function () {
      return {
        left: window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft || 0,
        top: window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
      }
    }
  }
}
</script>

<style scoped>
.back-top {
  background: url('../assets/icon_zhiding@2x.png') no-repeat center;
  width: 45px;
  height: 45px;
  background-size: cover;
  position: fixed;
  bottom: 55px;
  right: 10px;
}
</style>
