<template>
  <van-address-list
    v-model="chosenAddressId"
    :list="list"
    default-tag-text="默认"
    @add="handleAdd"
    @edit="handleEdit"
    @select="handleSelect"
    :switchable="switchable"
  />
  <van-empty description="您还没有填写地址哦" v-if="addressList.length === 0" />
</template>

<script>
import { AddressList, Empty } from 'vant'
import request from '@/utils/request'
import eventBus from '@/utils/eventBus'

export default {
  name: 'AddressList',
  components: {
    VanAddressList: AddressList,
    VanEmpty: Empty
  },
  props: {
    selectedAddressId: Number,
    switchable: Boolean
  },
  created() {
    this.chosenAddressId = this.selectedAddressId
    sessionStorage.removeItem('_to_edit_address')
    this.getAddressList()
  },
  data() {
    return {
      chosenAddressId: null,
      list: [],
      addressList: []
    }
  },
  methods: {
    async getAddressList() {
      let res = await request.get('/mall/getUserAddressList')
      this.list = res.data.map((item) => {
        return {
          id: item.id,
          name: item.name,
          tel: item.mobile,
          address: item.address + item.area,
          isDefault: item.is_default === 1
        }
      })
      this.addressList = res.data
    },
    handleAdd() {
      sessionStorage.removeItem('_to_edit_address')
      // sessionStorage.setItem('_to_edit_from', 'list')
      window.location.hash = 'addrEdit'
    },
    handleEdit(item, index) {
      // sessionStorage.setItem('_to_edit_from', 'list')
      sessionStorage.setItem('_to_edit_address', JSON.stringify(this.addressList[index]))
      window.location.hash = 'addrEdit'
    },
    handleSelect(item, index) {
      if (item.id !== this.chosenAddressId) {
        eventBus.emit('addressChange', this.addressList[index])
      }
      window.history.back()
    }
  }
}
</script>

<style scoped></style>
