<template>
  <section class="choose-city" :class="{ 'top-in': topIn }" v-show="modelValue">
    <div class="city-title border-bottom">
      选择城市
      <div class="btn-close" @click="handleClickClose">✕</div>
    </div>
    <div class="city-wrap" ref="cityWrap">
      <div class="city-hot" id="hot">
        <div class="city-hot-title">当前城市</div>
        <div class="city-hot-items">
          <div class="city-hot-item">{{ selectedCity.regionName }}</div>
        </div>
        <div class="city-hot-title">热门城市</div>
        <div class="city-hot-items">
          <div class="city-hot-item" v-for="(item, index) in hotCities" :key="index" @click="handleCityClick(item)">{{ item.regionName }}</div>
        </div>
      </div>
      <div class="city-list">
        <div v-for="(cityList, key) in cityData" :key="key" class="city-category" :id="key">
          <div class="city-letter">{{ key }}</div>
          <div v-for="(item, index) in cityList" :key="index" class="city-item-wrap">
            <div class="city-item-txt border-bottom" @click="handleCityClick(item)">{{ item.regionName }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="city-index left-in">
      <div v-for="(item, index) in letters" :key="index" class="city-index-item" @click="handleLetterClick(item)">
        {{ item === 'hot' ? '热门' : item }}
      </div>
    </div>
  </section>
</template>

<script>
import * as pageUtil from '@/utils/pageUtil'
import request from '@/utils/request'

export default {
  name: 'ChooseCity',
  components: {},
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    selectedCity: {
      type: Object
    }
  },
  data () {
    return {
      letters: ['hot', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'W', 'X', 'Y', 'Z'],
      hotCities: [
        {
          cityCode: '2',
          id: 10001,
          regionName: '北京市'
        },
        {
          cityCode: '65',
          id: 10002,
          regionName: '上海市'
        },
        {
          cityCode: '20',
          id: 10308,
          regionName: '广州市'
        },
        {
          cityCode: '24',
          id: 10258,
          regionName: '杭州市'
        },
        {
          cityCode: '112',
          id: 10309,
          regionName: '深圳市'
        },
        {
          cityCode: '83',
          id: 10277,
          regionName: '武汉市'
        },
        {
          cityCode: '7',
          id: 10354,
          regionName: '成都市'
        },
        {
          cityCode: '104',
          id: 10004,
          regionName: '重庆市'
        },
        {
          cityCode: '55',
          id: 10212,
          regionName: '南京市'
        }
      ],
      cities: {},
      topIn: false,
      cityData: {}
    }
  },
  mounted () {},
  methods: {
    handleClickClose () {
      this.topIn = false
      pageUtil.autoScroll()
      setTimeout(() => {
        this.$emit('update:modelValue', false)
      }, 100)
    },
    handleCityClick (item) {
      if (item.cityCode !== this.selectedCity.cityCode) {
        localStorage.setItem(
          'DGSS.CITY',
          JSON.stringify(item)
        )
        this.$emit('cityChange', item)
      }
      this.handleClickClose()
    },
    handleLetterClick (letter) {
      const obj = document.getElementById(letter)
      this.$refs.cityWrap.scrollTop = obj.offsetTop - 45
    }
  },
  watch: {
    modelValue (newValue) {
      if (newValue) {
        pageUtil.noScroll()
        if (!this.cityData.A) {
          request('/dgss/getAllRegion').then((res) => {
            this.cityData = res.data
          })
        }
        setTimeout(() => {
          this.topIn = true
        }, 200)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.choose-city {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  overflow: hidden;
  opacity: 0;
  transform: translate3d(0, -50px, 0);
  background: #fcfcfc;
  transition: opacity 0.2s ease-out, transform 0.2s ease-out, -webkit-transform 0.2s ease-out;

  &.top-in {
    opacity: 1;
    transform: translateZ(0);
  }

  .city-title {
    /*border-bottom: 1px solid #e9e9e9;*/
    width: 100%;
    height: 45px;
    background: #fff;
    color: #000;
    font-size: 16px;
    line-height: 45px;
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 300;

    .btn-close {
      position: absolute;
      top: 0;
      right: 0;
      width: 45px;
      height: 45px;
      color: #777;
      text-align: center;
      font-size: 20px;
    }
  }

  .city-wrap {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    padding-top: 45px;
  }

  .city-hot {
    background-color: #f5f5f5;
    padding: 4vw 0 2vw 4vw;
  }

  .city-hot-title {
    color: #9d9d9d;
    font-size: 13px;
    line-height: 24px;
    margin-bottom: 12px;
  }

  .city-hot-items {
    display: flex;
    flex-wrap: wrap;
  }

  .city-hot-item {
    box-sizing: border-box;
    width: 27.6vw;
    height: 8.26vw;
    line-height: 8.26vw;
    color: #9f9f9f;
    /*border: 1px solid #e4e4e4;*/
    text-align: center;
    font-size: 14px;
    margin-bottom: 12px;
    margin-right: 2.93vw;
    background-color: #fff;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      border: 1px solid #dddddd;
      width: 200%;
      height: 200%;
      transform: scale(0.5);
      transform-origin: 0 0;
      -webkit-transform: scale(0.5);
      -webkit-transform-origin: 0 0;
    }
  }

  .city-hot-item.active {
    /*border-color: #ff4d64;*/
    color: #ff4d64;

    &:after {
      border-color: #ff4d64;
    }
  }

  .city-letter {
    height: 27px;
    line-height: 27px;
    background: #f5f5f5;
    color: #777;
    font-size: 12px;
    /* border-top: 1rpx solid #e4e4e4; */
    /*border-bottom: 1px solid #e4e4e4;*/
    padding-left: 4vw;
    position: relative;
    box-sizing: border-box;
  }

  .city-item-wrap {
    background-color: #fefefe;
    height: 48px;
    line-height: 48px;
    font-size: 14px;
    padding-left: 4vw;

    &:last-child {
      .border-bottom {
        border: none;
      }
    }
  }

  .city-item-txt {
    /*border-bottom: 1px solid #e4e4e4;*/
    box-sizing: border-box;
    height: 100%;
  }

  .city-index {
    z-index: 300;
    position: fixed;
    right: 0;
    top: 50%;
    padding: 3px 0;
    overflow: hidden;
    //-webkit-transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
    //transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
    transition: opacity 0.2s ease-out, transform 0.2s ease-out;
    //transition: opacity 0.2s ease-out, transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
    will-change: transform;
    -webkit-transform: translate3d(100%, -50%, 0);
    transform: translate3d(100%, -50%, 0);

    &.left-in {
      opacity: 1;
      -webkit-transform: translate3d(0, -50%, 0);
      transform: translate3d(0, -50%, 0);
    }
  }

  .city-index-item {
    display: block;
    height: 20px;
    text-align: center;
    font-size: 12px;
    line-height: 20px;
    color: #009fe8;
    padding: 0 5px;
  }

  @media screen and (max-height: 600px) {
    .city-index-item {
      height: 18px;
      line-height: 18px;
    }
  }

  @media screen and (max-height: 550px) {
    .city-index-item {
      height: 15px;
      line-height: 15px;
    }
  }
}
</style>
