import dayjs from 'dayjs'

export function getWeek(dayString) {
  const targetDate = dayjs(dayString).format('YYYY-MM-DD')
  const today = dayjs().format('YYYY-MM-DD')
  const tomorrow = dayjs().add(1, 'd').format('YYYY-MM-DD')
  const houtian = dayjs().add(2, 'd').format('YYYY-MM-DD')

  let xq = '周' + ['日', '一', '二', '三', '四', '五', '六'][dayjs(dayString).day()]
  if (targetDate === today) {
    xq = '今天'
  }
  if (targetDate === tomorrow) {
    xq = '明天'
  }
  if (targetDate === houtian) {
    xq = '后天'
  }

  return xq
}

export function formatSecond2SFM(seconds) {
  let theTime = parseInt(seconds) // 秒
  let middle = 0 // 分
  let hour = 0 // 小时

  if (theTime > 60) {
    middle = parseInt(theTime / 60)
    theTime = parseInt(theTime % 60)
    if (middle > 60) {
      hour = parseInt(middle / 60)
      middle = parseInt(middle % 60)
    }
  }
  let result = '' + parseInt(theTime) + '秒'
  if (middle > 0) {
    result = '' + parseInt(middle) + '分' + result
  }
  if (hour > 0) {
    result = '' + parseInt(hour) + '小时' + result
  }
  return result
}
